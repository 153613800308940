<template>
	<div>
		<h3>Error <span>404</span></h3>
		<h5>资源未找到</h5>
		<div style="color:#333;font-size: 0.8rem;">页面在{{seconds}}秒后将返回首页</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				timeout: undefined,
				seconds: 5
			}
		},
		mounted() {
			this.countDown()
		},
		watch: {
			timeout(newVal, oldVal) {
				oldVal && clearTimeout(oldVal)
			}
		},
		methods: {
			countDown() {
				this.timeout = setTimeout(() => {
					if (this.seconds <= 0) {
						this.$router.replace('/')
						return
					}
					this.seconds--
					this.countDown()
				}, 1000)
			}
		},
		beforeRouteLeave(to, from, next) {
			clearTimeout(this.timeout)
			next()
		}
	}
</script>

<style>

</style>
